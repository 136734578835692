import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

// Asset Imports
import VimeoSVG from '../../images/icons/vimeo.inline.svg';
import FacebookSVG from '../../images/icons/facebook.inline.svg';
import InstagramSVG from '../../images/icons/instagram.inline.svg';
import Rubberduck from '../../images/rubberduck-logo.inline.svg';

// Component Imports
import { TechnicalSmall } from '../../theme/typography';
import Container from '../container';
import Button from '../buttons';

// Styled Components
const StyledFooter = styled.footer`
  background: var(--blue1);
  text-align: left;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 48px;
  padding-bottom: 64px;

  --primary-text: var(--white, white);

  @media (min-width: 800px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 48px;

  @media (min-width: 800px) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
  }
`;

const FooterSubContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  @media (min-width: 800px) {
    flex-direction: row;
    align-items: center;

    gap: 0px;
  }
`;

const RubberduckIcon = styled(Rubberduck)`
  max-height: 56px;
  max-width: fit-content;
  margin-right: 32px;
  align-self: left;
`;

const FooterNavigation = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    flex-direction: row;
    gap: 24px;
  }
`;

const Disclaimer = styled(TechnicalSmall)`
  color: var(--white);
  max-width: 440px;
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 16px;

  @media (min-width: 800px) {
    margin-top: 0;
    margin-left: 32px;
    align-self: center;
  }
`;

const SocialIcon = styled.a`
  width: 24px;
  height: 24px;
  fill: var(--blue4);
  cursor: pointer;
  transition: fill 0.3s ease-in;

  &:hover {
    fill: var(--accent);
  }
`;
// End Styled Components

const FooterNavRoutes = [
  {
    id: 0,
    title: 'Home',
    path: '/',
  },
  {
    id: 1,
    title: 'Portfolio',
    path: '/portfolio',
  },
];

const SimpleFooter = ({ simple }) => {
  return (
    <StyledFooter>
      <Container>
        <FooterContent>
          <Group>
            <RubberduckIcon />
            <FooterNavigation>
              {FooterNavRoutes.map((route) => (
                <Button
                  key={route.id}
                  label={route.title}
                  type={'link'}
                  path={route.path}
                />
              ))}
            </FooterNavigation>
          </Group>
          <Group>
            <Button
              label={'Contact Us'}
              type={'light'}
              path={'/contact'}
              arrow
            />
            <SocialLinks>
              <SocialIcon
                href='https://vimeo.com/user95098603'
                rel='noopener noreferrer'
                target='_blank'
                aria-label='Vimeo'
              >
                <VimeoSVG />
              </SocialIcon>

              <SocialIcon
                href='https://www.facebook.com/www.rubberduck.ie/'
                rel='noopener noreferrer'
                target='_blank'
                aria-label='Facebook'
              >
                <FacebookSVG />
              </SocialIcon>

              <SocialIcon
                href='https://www.instagram.com/rubberduck.ie/'
                rel='noopener noreferrer'
                target='_blank'
                aria-label='Instagram'
              >
                <InstagramSVG />
              </SocialIcon>
            </SocialLinks>
          </Group>
        </FooterContent>
        <FooterSubContent>
          <Disclaimer>
            {`©${new Date().getFullYear()} Rubberduck Ltd. All rights reserved. Various trademarks held by their respective owners.`}
          </Disclaimer>
        </FooterSubContent>
      </Container>
    </StyledFooter>
  );
};
SimpleFooter.propTypes = {};

SimpleFooter.defaultProps = {};

export default SimpleFooter;
