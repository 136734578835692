import { Link, useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

// Asset Imports
import VimeoSVG from '../../images/icons/vimeo.inline.svg';
import FacebookSVG from '../../images/icons/facebook.inline.svg';
import InstagramSVG from '../../images/icons/instagram.inline.svg';
import Rubberduck from '../../images/rubberduck-logo.inline.svg';
import BASLogoSVG from '../../images/bas-badge.inline.svg';

// Component Imports
import { Technical, TechnicalSmall } from '../../theme/typography';
import Container from '../container';
import Button from '../buttons';
import SimpleFooter from './footerSimple';

// Styled Components
const StyledFooter = styled.footer`
  background: var(--blue1);
  text-align: left;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 48px;
  padding-bottom: 64px;

  --primary-text: var(--white, white);

  @media (min-width: 800px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;
  padding-bottom: 32px;

  @media (min-width: 800px) {
    flex-direction: row;
    padding-bottom: 64px;
  }
`;

const FooterSubContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid var(--blue3);
  padding-top: 24px;
`;

const FooterSubLinks = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 24px;
  gap: 12px;

  @media (min-width: 800px) {
    gap: 16px;
  }
`;

const FooterSubLink = styled(TechnicalSmall)`
  color: var(--white);
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 0.5;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  @media (min-width: 800px) {
    flex-direction: row;

    gap: 0px;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const RubberduckIcon = styled(Rubberduck)`
  max-height: 56px;
  max-width: 56px;
  margin-right: 32px;
`;

const FooterLogos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-end;
  justify-content: flex-end;

  @media (max-width: 800px) {
    align-self: flex-end;
  }
`;

const AdGreenLogo = styled.div`
  svg {
    height: max(48px, 2.5vw);
  }
`;

const FooterNavigation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;

  @media (min-width: 800px) {
    flex-direction: row;
    gap: 48px;
  }
`;

const FooterNavColumn = styled.div`
  display: flex;
  flex-direction: column;

  gap: 4px;
`;

const NavLink = styled(Technical)`
  display: none;
  color: var(--white);

  &:hover {
    color: var(--accent);
  }

  @media (min-width: 800px) {
    display: block;
  }
`;

const LinkStyledHeadingWrap = styled.span`
  display: none;
  cursor: unset;

  @media (min-width: 800px) {
    display: inline-block;
  }
`;

const MobileNavLink = styled(Button)`
  @media (min-width: 800px) {
    display: none;
  }
`;

const Badge = styled.span`
  display: inline-block;
  font-family: Poppins;
  font-size: 12px;
  line-height: 12px;
  background: var(--blue6);
  color: var(--blue2);
  border-radius: 16px;
  padding: 2px 8px;
  margin-left: 6px;
  transform: translateY(-2px);
`;

const Disclaimer = styled(TechnicalSmall)`
  color: var(--white);
  max-width: 440px;
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 16px;
  flex-wrap: nowrap;

  @media (min-width: 800px) {
    margin-top: 0;
    margin-left: 32px;
    align-self: center;
  }
`;

const SocialIcon = styled.a`
  width: 24px;
  height: 24px;
  fill: var(--blue4);
  cursor: pointer;
  transition: fill 0.3s ease-in;

  &:hover {
    fill: var(--accent);
  }
`;

const MobileContactAndSocial = styled.div`
  margin-top: -8px;
  margin-bottom: 16px;
  @media (min-width: 800px) {
    display: none;
  }
`;

const DesktopContactAndSocial = styled.div`
  display: none;

  @media (min-width: 800px) {
    display: block;
  }
`;

const BASLogo = styled(BASLogoSVG)`
  width: 200px;

  @media (min-width: 1200px) {
    width: 250px;
  }
`;
// End Styled Components

const FooterSubLinkRoutes = [
  {
    id: 0,
    title: 'Privacy',
    path: '/privacy-policy',
  },
  {
    id: 1,
    title: 'Terms',
    path: '/terms-and-conditions',
  },
  {
    id: 2,
    title: 'Cookies',
    path: '/cookies-policy',
  },
  {
    id: 3,
    title: 'FAQ',
    path: '/faq',
  },
  {
    id: 4,
    title: 'Contact',
    path: '/contact',
  },
];

const ContactAndSocial = () => (
  <Group>
    <Button label={'Contact Us'} type={'light'} path={'/contact'} arrow />
    <SocialLinks>
      <SocialIcon
        href='https://vimeo.com/user95098603'
        rel='noopener noreferrer'
        target='_blank'
        aria-label='Vimeo'
      >
        <VimeoSVG />
      </SocialIcon>

      <SocialIcon
        href='https://www.facebook.com/www.rubberduck.ie/'
        rel='noopener noreferrer'
        target='_blank'
        aria-label='Facebook'
      >
        <FacebookSVG />
      </SocialIcon>

      <SocialIcon
        href='https://www.instagram.com/rubberduck.ie/'
        rel='noopener noreferrer'
        target='_blank'
        aria-label='Instagram'
      >
        <InstagramSVG />
      </SocialIcon>
    </SocialLinks>
  </Group>
);

const Footer = ({ simple }) => {
  const data = useStaticQuery(
    graphql`
      query FooterQuery {
        allSanityProductPage {
          nodes {
            productTag {
              label
              slug {
                current
              }
            }
          }
        }
        allSanityIndustryPage {
          nodes {
            industryTag {
              label
              slug {
                current
              }
            }
          }
        }
        allSanityOpenRole {
          totalCount
        }
      }
    `,
  );

  if (simple) {
    return <SimpleFooter />;
  } else {
    return (
      <StyledFooter>
        <Container>
          <FooterContent>
            <RubberduckIcon />
            <Group>
              <FooterNavigation>
                <FooterNavColumn key={0}>
                  <Button
                    label={'Products'}
                    type={'link'}
                    path={'/products'}
                    arrow
                  />
                  {data.allSanityProductPage.nodes.map((route, index) => {
                    return (
                      <NavLink
                        as={Link}
                        key={index}
                        to={`/products/${route.productTag.slug.current}`}
                      >
                        {route.productTag.label}
                      </NavLink>
                    );
                  })}
                </FooterNavColumn>
                <FooterNavColumn key={1}>
                  <Button
                    label={'Industries'}
                    type={'link'}
                    path={'/solutions'}
                    arrow
                  />
                  {data.allSanityIndustryPage.nodes.map((route, index) => {
                    return (
                      <NavLink
                        as={Link}
                        key={index}
                        to={`/industry/${route.industryTag.slug.current}`}
                      >
                        {route.industryTag.label}
                      </NavLink>
                    );
                  })}
                </FooterNavColumn>
                <FooterNavColumn key={2}>
                  <LinkStyledHeadingWrap>
                    <Button
                      label={'Company'}
                      type={'link'}
                      as={'span'}
                      style={{ cursor: 'unset' }}
                    ></Button>
                  </LinkStyledHeadingWrap>
                  <NavLink as={Link} key={0} to={'/portfolio'} mobile={'true'}>
                    Portfolio
                  </NavLink>
                  <NavLink as={Link} key={1} to={'/directors'} mobile={'true'}>
                    Directors Roster
                  </NavLink>
                  <NavLink as={Link} key={2} to={'/careers'} mobile={'true'}>
                    Careers
                    {data.allSanityOpenRole.totalCount > 0 && (
                      <Badge>{data.allSanityOpenRole.totalCount}</Badge>
                    )}
                  </NavLink>
                  <NavLink as={Link} key={3} to={'/contact'}>
                    Contact Us
                  </NavLink>
                </FooterNavColumn>
                <MobileNavLink
                  key={'portfolio'}
                  label={'Portfolio'}
                  type={'link'}
                  path={'/portfolio'}
                  arrow
                />
                <MobileNavLink
                  key={'directors'}
                  label={'Directors'}
                  type={'link'}
                  path={'/directors'}
                  arrow
                />
                <MobileNavLink
                  key={'careers'}
                  label={'Careers'}
                  type={'link'}
                  path={'/careers'}
                  arrow
                ></MobileNavLink>
              </FooterNavigation>
            </Group>
            <MobileContactAndSocial>
              <ContactAndSocial />
            </MobileContactAndSocial>
            <FooterLogos>
              <AdGreenLogo>
                <svg
                  viewBox='0 0 141 49'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g>
                    <path
                      d='M120.691 1.53059C121.971 1.28182 122.792 1.58799 123.48 2.87009C126.021 7.61577 128.734 12.2849 131.39 16.9732C131.715 17.5281 132.078 18.0639 132.842 18.5232C132.842 12.9355 132.842 7.32873 132.842 1.58799C135.268 1.58799 137.446 1.58799 139.777 1.58799C139.777 11.1559 139.777 20.6473 139.777 30.4448C137.523 30.4448 135.326 30.3874 133.129 30.4639C131.944 30.5213 131.677 29.6794 131.275 28.9522C128.715 24.4361 126.174 19.901 123.633 15.3658C123.232 14.6386 122.773 13.9306 121.799 13.2991C121.799 16.8966 121.799 20.475 121.799 24.0726C119.487 24.0917 117.194 24.1108 114.883 24.1108C114.825 24.0343 114.768 23.9386 114.711 23.8621C114.711 22.0442 114.711 20.2454 114.73 18.4275L114.883 18.1979C116.373 18.1979 117.863 18.1979 119.334 18.1979C119.334 16.1312 119.334 14.3898 119.334 12.5911C117.729 12.5911 116.296 12.5911 114.883 12.5911L114.73 12.3997C114.73 10.7732 114.711 9.1275 114.711 7.50096C114.768 7.40528 114.825 7.3096 114.883 7.21392C116.755 7.21392 118.646 7.21392 120.691 7.21392C120.691 5.12812 120.691 3.32935 120.691 1.53059Z'
                      fill='#E7398B'
                    />
                    <path
                      d='M22.1817 28.2442C21.7423 27.3066 21.322 26.3498 20.8634 25.3356C17.5773 25.3356 14.3102 25.3356 10.8712 25.3356C10.2789 26.9239 9.70571 28.5121 9.05612 30.2726C6.53416 30.2726 3.974 30.2726 1.18457 30.2726C4.81465 20.6282 8.38742 11.1368 11.9984 1.5498C14.5777 1.5498 17.0614 1.5498 19.7744 1.5498C20.5577 3.34857 21.3984 5.24301 22.239 7.1566C22.3346 8.15166 22.4492 9.16585 22.5447 10.1609C22.4874 13.6819 22.3537 17.1838 22.3728 20.7047C22.3919 23.1733 22.5829 25.6226 22.6976 28.0912C22.5065 28.1103 22.3346 28.1677 22.1817 28.2442ZM19.0866 19.3844C18.0358 16.0356 17.0805 13.0313 15.9533 9.43375C15.2846 11.4239 14.7878 12.8591 14.3293 14.2751C13.7943 15.9017 13.2976 17.5282 12.7244 19.3652C14.8451 19.3844 16.7175 19.3844 19.0866 19.3844Z'
                      fill='#44AB37'
                    />
                    <path
                      d='M22.5257 10.1802C22.4301 9.18514 22.3155 8.17095 22.22 7.17588C22.22 5.35798 22.22 3.55922 22.22 1.45428C26.3659 1.45428 30.1106 1.37774 33.8744 1.47342C36.0907 1.53082 38.3261 1.68391 40.4659 2.1623C42.2045 2.54502 44.0005 3.21477 44.9748 4.97527C44.8793 5.51107 44.9557 6.19996 44.6501 6.58268C42.9496 8.68761 42.3765 11.2135 41.6696 13.7012C40.9053 9.07033 36.4919 7.00366 30.4927 8.20922C30.0342 12.0938 30.187 21.853 30.7602 24.0345C31.7728 24.0345 32.8236 24.0919 33.8744 24.0154C38.8419 23.6518 40.485 22.408 41.6696 18.2555C41.7842 18.6 41.9561 18.9253 42.0326 19.2888C42.6822 22.0635 43.4464 24.7617 46.0639 26.4265C46.1212 26.5605 46.1403 26.7135 46.1021 26.8666C44.1724 27.8617 42.3383 29.0481 40.3131 29.7944C37.1224 30.9617 33.7216 30.4833 30.3972 30.4067C30.3972 30.1197 30.4545 29.7944 30.3781 29.5456C28.0854 23.3648 25.7927 17.203 23.4809 11.0796C23.3281 10.716 22.8505 10.4672 22.5257 10.1802Z'
                      fill='#F5B336'
                    />
                    <path
                      d='M114.863 30.3687C110.393 30.3687 105.922 30.3687 101.432 30.3687C101.776 28.3594 102.12 26.3502 102.502 24.073C100.744 24.073 99.1394 24.073 97.5345 24.073L97.3625 23.9773C97.3625 22.1212 97.3625 20.2459 97.3625 18.3897L97.5345 18.3131C99.1394 18.3131 100.763 18.3131 102.445 18.3131C102.941 16.3422 103.4 14.5625 103.916 12.5341C101.528 12.5341 99.5215 12.5341 97.5345 12.5341L97.3625 12.4767C97.3625 10.7928 97.3625 9.08969 97.3625 7.40574L97.5536 7.31006C100.076 7.34833 102.617 7.3866 105.139 7.42487C105.139 9.03228 105.139 10.6397 105.139 12.4385C108.425 12.4385 111.596 12.4385 114.749 12.4385L114.902 12.6298C114.902 14.5051 114.902 16.3613 114.902 18.2366L114.749 18.4662C111.577 18.4662 108.425 18.4662 105.253 18.4662C105.253 20.4181 105.253 22.1786 105.253 23.9008C108.558 23.9008 111.654 23.9008 114.749 23.9008C114.806 23.9965 114.863 24.073 114.921 24.1496C114.882 26.2162 114.882 28.2829 114.863 30.3687Z'
                      fill='#EF9102'
                    />
                    <path
                      d='M61.7878 1.77977C62.724 1.66496 63.6602 1.4736 64.5964 1.45446C68.3411 1.41619 72.1049 1.51187 75.8496 1.41619C78.4862 1.35879 81.0082 1.7415 83.4155 2.81311C83.3964 2.96619 83.3964 3.11928 83.4537 3.27237C82.2691 9.26187 81.0846 15.2322 79.8618 21.2218C79.7663 21.6427 79.346 21.9872 79.0594 22.3699C78.2761 20.5711 76.8813 19.8631 74.9517 19.9588C73.1366 20.0353 71.3407 20.0353 69.5256 20.0736C69.4874 18.3131 69.4683 16.5717 69.4301 14.8113C69.4683 14.5434 69.5065 14.2755 69.5448 14.0076C71.6846 14.0458 73.8053 14.1989 75.9452 14.0841C78.2569 13.9693 79.4224 12.6872 79.4033 10.6397C79.3842 8.80261 78.2378 7.55879 76.0598 7.38656C73.9582 7.23348 71.8374 7.34829 69.6594 7.34829C69.5448 8.24767 69.4683 8.89829 69.3919 9.56805C69.2009 9.16619 68.9716 8.76434 68.8187 8.34335C67.5578 5.12854 65.3033 2.98533 61.9407 2.02854C61.8452 1.99027 61.8069 1.89459 61.7878 1.77977Z'
                      fill='#3770A5'
                    />
                    <path
                      d='M83.4346 3.27206C83.3773 3.11897 83.3773 2.96588 83.3964 2.8128C83.7021 1.68378 84.4854 1.41588 85.5936 1.43502C89.5293 1.51156 93.4842 1.49243 97.42 1.5307C97.4582 3.46341 97.4773 5.37699 97.5155 7.30971L97.3245 7.40539C95.3948 7.38625 93.4651 7.27144 91.5545 7.36712C91.0005 7.38625 90.1216 7.84551 89.9879 8.2665C89.6058 9.56773 89.5102 10.9646 89.3001 12.4764C92.1468 12.4764 94.7452 12.4764 97.3245 12.4764L97.4964 12.5338C97.4964 14.4665 97.4964 16.3801 97.4964 18.3128L97.3245 18.3893C95.3184 18.3702 93.3123 18.3319 91.3062 18.3319C88.0391 18.3128 88.0391 18.3319 87.3131 21.4511C87.2749 21.585 87.0456 21.6807 86.8927 21.7955C85.7464 20.035 85.2688 17.7579 82.9761 16.9542C83.6448 16.3801 84.2371 15.9017 84.8102 15.4233C88.1919 12.6486 87.6188 6.46773 84.6765 3.75045C84.409 3.44428 83.874 3.42514 83.4346 3.27206Z'
                      fill='#65C5E1'
                    />
                    <path
                      d='M83.4345 3.27197C83.8739 3.42506 84.4089 3.44419 84.7146 3.73123C87.6568 6.44852 88.23 12.6294 84.8483 15.4041C84.256 15.8825 83.6829 16.38 83.0142 16.9349C85.3068 17.7386 85.7845 20.0158 86.9308 21.7763C86.9499 22.5226 86.969 23.2689 86.9881 24.0152C87.141 26.1393 87.3129 28.2825 87.4658 30.4065C84.4853 30.4065 81.5048 30.4065 78.1804 30.4065C78.4861 28.4738 78.7727 26.8281 78.9829 25.1633C79.0975 24.2448 79.0402 23.288 79.0593 22.3504C79.3268 21.9677 79.7662 21.6232 79.8617 21.2022C81.0654 15.2319 82.2499 9.26148 83.4345 3.27197Z'
                      fill='#07ACE3'
                    />
                    <path
                      d='M69.4301 14.811C69.4683 16.5715 69.4874 18.3128 69.5256 20.0733C69.5256 23.403 69.5256 26.7326 69.5256 30.2727C66.9655 30.2727 64.5391 30.2727 61.9598 30.2727C61.9216 29.6795 61.8834 29.1246 61.8452 28.5696C61.8261 26.2542 61.8069 23.9196 61.7878 21.6042C61.8069 21.2406 61.8261 20.8579 61.8452 20.4943C61.8261 18.5807 61.8069 16.6672 61.7878 14.7536L61.8643 14.6196C64.3862 14.677 66.9082 14.7536 69.4301 14.811Z'
                      fill='#7E286A'
                    />
                    <path
                      d='M46.0639 26.4073C43.4464 24.7425 42.6822 22.0443 42.0326 19.2696C41.9561 18.9252 41.7842 18.5807 41.6696 18.2363C41.6696 17.6622 41.6696 17.0882 41.6887 16.5141C41.6887 16.1505 41.6887 15.7678 41.6887 15.4042C41.6887 14.8301 41.6887 14.2561 41.6887 13.682C42.3956 11.1943 42.9687 8.6684 44.6691 6.56346C44.9748 6.18075 44.8984 5.51099 44.9939 4.95605C45.357 4.97519 45.7391 4.97519 46.1021 4.99433C47.5732 7.73075 48.9488 10.5054 49.4647 13.6054C49.4456 15.1746 49.4456 16.7437 49.4265 18.3128C48.796 21.2215 48.0509 24.0727 46.0639 26.4073Z'
                      fill='#EB6709'
                    />
                    <path
                      d='M49.4455 13.6246C48.9296 10.5246 47.5349 7.74995 46.0829 5.01353C49.7321 1.2055 54.2983 0.459205 59.2849 1.14809C60.1256 1.26291 60.9662 1.56908 61.7878 1.77958C61.8069 1.89439 61.8451 1.99007 61.9215 2.06661C61.8833 4.80303 61.8451 7.53945 61.8069 10.2759C59.2276 7.50118 56.3235 6.71661 53.4004 7.99871C50.9739 9.08945 50.076 11.2709 49.4455 13.6246Z'
                      fill='#E20C18'
                    />
                    <path
                      d='M46.0638 26.4073C48.0699 24.0728 48.796 21.2215 49.4264 18.332C49.5793 18.887 49.7321 19.4419 49.9232 19.9777C50.8021 22.5228 52.4069 24.2833 55.1964 24.6277C57.9858 24.9722 60.2976 24.1493 61.7878 21.6043C61.8069 23.9197 61.826 26.2543 61.8451 28.5697C58.9029 31.7845 55.2919 31.3635 51.7764 30.1005C49.7512 29.3734 47.9935 27.9382 46.1021 26.8283C46.1212 26.6944 46.1212 26.5413 46.0638 26.4073Z'
                      fill='#E20C18'
                    />
                    <path
                      d='M22.5256 10.1802C22.8504 10.4863 23.328 10.716 23.4618 11.0796C25.7927 17.2222 28.0663 23.3647 30.3589 29.5073C30.4545 29.7561 30.378 30.0814 30.378 30.3684C27.8561 30.3302 25.3341 30.2919 22.8122 30.2728C22.774 29.5456 22.7167 28.8184 22.6785 28.0913C22.5638 25.6228 22.3537 23.1734 22.3537 20.7049C22.3346 17.203 22.4683 13.682 22.5256 10.1802Z'
                      fill='#FDEB02'
                    />
                    <path
                      d='M87.4659 30.4067C87.3131 28.2826 87.1411 26.1394 86.9883 24.0153C90.4464 23.9962 93.9045 23.9771 97.3627 23.9771L97.5346 24.0727C97.5155 26.1777 97.4773 28.2826 97.4582 30.3875C94.1147 30.3875 90.7903 30.4067 87.4659 30.4067Z'
                      fill='#65C5E1'
                    />
                    <path
                      d='M97.5345 7.30977C97.4963 5.37706 97.4772 3.46348 97.439 1.53076C100.725 1.53076 103.992 1.53076 107.278 1.53076C106.877 3.44434 106.476 5.35792 106.075 7.2715C105.75 7.32891 105.444 7.36718 105.119 7.42459C102.578 7.38632 100.056 7.34805 97.5345 7.30977Z'
                      fill='#7CB829'
                    />
                    <path
                      d='M106.075 7.2715C106.476 5.35792 106.877 3.44434 107.278 1.53076C109.8 1.53076 112.341 1.53076 114.863 1.53076C114.882 3.42521 114.882 5.33879 114.902 7.23323C114.825 7.30977 114.768 7.40545 114.73 7.52027C111.845 7.44372 108.96 7.34805 106.075 7.2715Z'
                      fill='#F08F01'
                    />
                    <path
                      d='M114.864 30.3682C114.864 28.3016 114.883 26.2158 114.883 24.1491C117.194 24.13 119.487 24.1108 121.799 24.1108C121.799 26.1201 121.799 28.1294 121.799 30.3874C119.449 30.3682 117.156 30.3682 114.864 30.3682Z'
                      fill='#E53813'
                    />
                    <path
                      d='M114.902 7.25227C114.883 5.35783 114.883 3.44425 114.864 1.5498C116.812 1.5498 118.742 1.5498 120.691 1.5498C120.691 3.34857 120.691 5.14734 120.691 7.27141C118.666 7.25227 116.774 7.25227 114.902 7.25227Z'
                      fill='#E53712'
                    />
                    <path
                      d='M97.439 30.3685C97.4581 28.2636 97.4963 26.1586 97.5154 24.0537C99.1203 24.0537 100.744 24.0537 102.483 24.0537C102.101 26.3309 101.757 28.3401 101.413 30.3494C100.114 30.3685 98.7764 30.3685 97.439 30.3685Z'
                      fill='#7DB82B'
                    />
                    <path
                      d='M22.6976 28.0913C22.7358 28.8185 22.7931 29.5456 22.8314 30.2728C22.6212 29.603 22.411 28.9141 22.1818 28.2444C22.3346 28.1679 22.5066 28.1104 22.6976 28.0913Z'
                      fill='#F5B336'
                    />
                    <path
                      d='M114.883 18.2362C114.883 16.3609 114.883 14.5047 114.883 12.6294C116.296 12.6294 117.729 12.6294 119.334 12.6294C119.334 14.4282 119.334 16.1695 119.334 18.2362C117.863 18.2362 116.373 18.2362 114.883 18.2362Z'
                      fill='#E53716'
                    />
                    <path
                      d='M97.5347 18.3127C97.5347 16.38 97.5347 14.4664 97.5347 12.5337C99.5408 12.5337 101.528 12.5337 103.916 12.5337C103.4 14.5621 102.961 16.3226 102.445 18.3127C100.744 18.3127 99.1395 18.3127 97.5347 18.3127Z'
                      fill='#7CB829'
                    />
                    <path
                      d='M69.3727 9.58678C69.3727 10.1609 69.3727 10.7541 69.3727 11.3281C66.9081 11.3281 64.4435 11.3473 61.9788 11.3473C61.9215 10.9837 61.8642 10.6392 61.8069 10.2757C61.8451 7.53925 61.8833 4.80283 61.9215 2.06641C65.2841 3.0232 67.5386 5.14727 68.7996 8.38122C68.9524 8.80221 69.1817 9.18492 69.3727 9.58678Z'
                      fill='#7A266A'
                    />
                    <path
                      d='M61.9789 11.3473C64.4435 11.3473 66.9082 11.3281 69.3728 11.3281C69.4301 12.2275 69.4874 13.1269 69.5447 14.0071C69.5065 14.275 69.4683 14.5429 69.4301 14.8108C66.9082 14.7534 64.3862 14.6769 61.8643 14.6195C61.9025 13.5287 61.9407 12.438 61.9789 11.3473Z'
                      fill='#3D6BA1'
                    />
                    <path
                      d='M61.8261 20.4942C60.8708 20.475 59.9347 20.3985 58.9794 20.4176C57.9668 20.4368 57.3363 20.1114 57.3554 18.9824C57.3745 17.6812 57.3554 16.3608 57.3554 14.7534C58.9603 14.7534 60.355 14.7534 61.7497 14.7534C61.7879 16.667 61.807 18.5806 61.8261 20.4942Z'
                      fill='#E31420'
                    />
                  </g>
                  <path
                    d='M141 47.8322H140.152V46.6002C139.608 47.4002 138.616 48.0242 137.464 48.0242C135.416 48.0242 134.008 46.4562 134.008 43.9762C134.008 41.5282 135.416 39.9122 137.464 39.9122C138.568 39.9122 139.528 40.4722 140.152 41.3522V37.1602H141V47.8322ZM137.608 47.2722C138.696 47.2722 139.704 46.6322 140.152 45.8962V42.0722C139.704 41.3202 138.696 40.6642 137.608 40.6642C135.896 40.6642 134.904 42.1042 134.904 43.9762C134.904 45.8482 135.896 47.2722 137.608 47.2722Z'
                    fill='white'
                  />
                  <path
                    d='M128.322 48.0241C126.066 48.0241 124.45 46.3441 124.45 43.9601C124.45 41.7201 126.034 39.9121 128.178 39.9121C130.53 39.9121 131.826 41.7841 131.826 44.0081V44.2321H125.362C125.442 45.9121 126.562 47.3041 128.386 47.3041C129.362 47.3041 130.258 46.9361 130.914 46.2321L131.346 46.7761C130.578 47.5761 129.602 48.0241 128.322 48.0241ZM130.978 43.5761C130.962 42.2641 130.098 40.6321 128.162 40.6321C126.338 40.6321 125.426 42.2321 125.362 43.5761H130.978Z'
                    fill='white'
                  />
                  <path
                    d='M121.412 38.888C121.076 38.888 120.772 38.6 120.772 38.248C120.772 37.896 121.076 37.624 121.412 37.624C121.764 37.624 122.052 37.896 122.052 38.248C122.052 38.6 121.764 38.888 121.412 38.888ZM121.828 47.832H120.996V40.104H121.828V47.832Z'
                    fill='white'
                  />
                  <path
                    d='M117.386 47.832H116.554V40.84H115.274V40.104H116.554V39.4C116.554 37.896 117.354 37 118.65 37C119.194 37 119.626 37.112 120.122 37.544L119.722 38.12C119.466 37.88 119.146 37.72 118.73 37.72C117.85 37.72 117.386 38.328 117.386 39.4V40.104H118.954V40.84H117.386V47.832Z'
                    fill='white'
                  />
                  <path
                    d='M112.796 38.888C112.46 38.888 112.156 38.6 112.156 38.248C112.156 37.896 112.46 37.624 112.796 37.624C113.148 37.624 113.436 37.896 113.436 38.248C113.436 38.6 113.148 38.888 112.796 38.888ZM113.212 47.832H112.38V40.104H113.212V47.832Z'
                    fill='white'
                  />
                  <path
                    d='M109.174 48.0242C108.15 48.0242 107.654 47.4002 107.654 46.3442V40.8402H106.374V40.1042H107.654V37.9922H108.502V40.1042H110.07V40.8402H108.502V46.2162C108.502 46.8402 108.758 47.2722 109.302 47.2722C109.67 47.2722 109.99 47.0962 110.166 46.9042L110.486 47.5442C110.182 47.8322 109.814 48.0242 109.174 48.0242Z'
                    fill='white'
                  />
                  <path
                    d='M102.767 47.8318H101.935V40.1038H102.767V41.4318C103.423 40.5678 104.303 39.9438 105.375 39.9438V40.8558C105.231 40.8238 105.119 40.8238 104.959 40.8238C104.175 40.8238 103.119 41.5278 102.767 42.1998V47.8318Z'
                    fill='white'
                  />
                  <path
                    d='M95.8012 48.0241C93.5452 48.0241 91.9292 46.3441 91.9292 43.9601C91.9292 41.7201 93.5132 39.9121 95.6572 39.9121C98.0092 39.9121 99.3052 41.7841 99.3052 44.0081V44.2321H92.8412C92.9212 45.9121 94.0412 47.3041 95.8652 47.3041C96.8412 47.3041 97.7372 46.9361 98.3932 46.2321L98.8252 46.7761C98.0572 47.5761 97.0812 48.0241 95.8012 48.0241ZM98.4572 43.5761C98.4412 42.2641 97.5772 40.6321 95.6412 40.6321C93.8172 40.6321 92.9052 42.2321 92.8412 43.5761H98.4572Z'
                    fill='white'
                  />
                  <path
                    d='M87.303 48.0241C85.031 48.0241 83.543 46.2161 83.543 43.9601C83.543 41.7041 85.031 39.9121 87.303 39.9121C88.647 39.9121 89.415 40.4401 90.039 41.2241L89.463 41.7361C88.903 40.9681 88.199 40.6641 87.351 40.6641C85.543 40.6641 84.439 42.1041 84.439 43.9601C84.439 45.8161 85.543 47.2721 87.351 47.2721C88.199 47.2721 88.903 46.9521 89.463 46.1841L90.039 46.6961C89.415 47.4961 88.647 48.0241 87.303 48.0241Z'
                    fill='white'
                  />
                </svg>
              </AdGreenLogo>
              <BASLogo />
            </FooterLogos>
          </FooterContent>
          <FooterSubContent>
            <Col>
              <FooterSubLinks>
                {FooterSubLinkRoutes.map((route, index) => {
                  return (
                    <FooterSubLink key={route.id} as={Link} to={route.path}>
                      {route.title}
                    </FooterSubLink>
                  );
                })}
              </FooterSubLinks>
              <Disclaimer>
                {`©${new Date().getFullYear()} Rubberduck Ltd. All rights reserved. Various trademarks held by their respective owners.`}
              </Disclaimer>
            </Col>
            <DesktopContactAndSocial>
              <ContactAndSocial />
            </DesktopContactAndSocial>
          </FooterSubContent>
        </Container>
      </StyledFooter>
    );
  }
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
