import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import * as CSSVariables from './css-variables.scss';

//TODO: Remove Theme
export const theme = {
  color: {
    accent: '#FED136',
    blue1: '#08273A',
    blue2: '#2A394B',
    blue3: '#3A495C',
    blue4: '#7E8FA2',
    blue5: '#BFCBD9',
    blue6: '#F8FBFF',
    error: '#CF1124',
    success: '#27AB83',
    white: '#FFFFFF',
  },
  fontSize: {
    // Comments are pixels @ base 16
    xxxs: '0.875em', // 14
    xxs: '1em', // 16
    xs: '1.125em', // 18
    sm: '1.25em', // 20
    md: '1.375em', // 22
    lg: '1.625em', // 26
    xl: '2.25em', // 36
    xxl: '3em', // 48
  },
  fontWeight: {
    // TODO are these font weights even included in site?
    light: 300,
    regular: 400,
    semibold: 600,
  },
  spacing: {
    xs: '10px',
    sm: '20px',
    md: '30px',
    lg: '40px',
    xl: '50px',
    xxl: '70px',
    xxxl: '175px',
    sectionPaddingSm: '7em',
    sectionPaddingLg: '10.5em',
  },
  boxShadow: {
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    coloured:
      '0px 4px 6px rgba(126, 143, 162, 0.05), 0px 10px 15px rgba(126, 143, 162, 0.1)',
    xl: '0 20px 25px -5px rgba(126, 143, 162, 0.1), 0 10px 10px -5px rgba(126, 143, 162, 0.04)',
    xxl: '0 22px 28px -7px rgba(126, 143, 162, 0.1), 0 11px 11px -6px rgba(126, 143, 162, 0.04) ',
  },
  stripeMovement: {
    left: 'translate(-750px, -750px)',
    right: 'translate(750px, -750px)',
    leftAndUp: 'translate(-750px, 750px)',
    rightAndUp: 'translate(750px, 750px)',
  },
  transition: {
    hover: '150ms cubic-bezier(0.215,0.61,0.355,1)',
  },
  // TODO borderRadius, lineHeight, letterSpacing?, font
};

export const GlobalStyle = createGlobalStyle`
    ${normalize}

    /* ==================================================================== */
    /* Custom Micro Reset | @bcolwell */
    /* ==================================================================== */
    *, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* outline: 1px solid green; */
    }

    a{text-decoration:none; color:inherit; cursor:pointer;}
    button{background-color:transparent; color:inherit; border-width:0; padding:0; cursor:pointer;}
    button:focus {outline: none;}
    figure{margin:0;}
    input {border: 0; }
    input::-moz-focus-inner {border:0; padding:0; margin:0;}
    ul, ol, dd{margin:0; padding:0; list-style:none;}
    h1, h2, h3, h4, h5, h6{margin:0; font-size:inherit; font-weight:inherit;}
    p{margin:0;}
    cite {font-style:normal;}
    fieldset{border-width:0; padding:0; margin:0;}
    textArea{border: 0;}

    /* ==================================================================== */
    /* Global Styles */
    /* ==================================================================== */

    /* Theme */
    ${CSSVariables} //! This is split into a separate CSS file to accomodate intellisense

    body {
        font-family: Proxima Nova, sans-serif;
        font-size: 16px; //For browsers that don't support calc()
        // font-size: calc(
        //   $min_font + ($max_font - $min_font) *
        //     ((100vw - $min_width) / ($max_width - $min_width))
        // );
        font-size: calc(
            14px + (16 - 14) * ((100vw - 375px) / (1400 - 375))
        );
        font-weight: var(--font-weight-regular);


      }

      body:has(dialog[open]) {
        overflow: hidden;
      }

      input, textarea {
        font-family: Proxima Nova;
        font-weight: var(--font-weight-light);
        font-size: var(--font-size-P1);
        line-height: 24px;
        letter-spacing: 0em;
      }
`;
